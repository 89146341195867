export interface ICategory {
  id: number
  type: ESuggestionType
  name: string
}

export enum ESuggestionType {
  CATEGORY = 'CATEGORY',
  PRODUCER = 'PRODUCER',
  SKU = 'SKU',
  SUPPLIER = 'SUPPLIER',
  ACTIVE_SUBSTANCES = 'ACTIVE_SUBSTANCES',
}

interface IBaseMarketSuggestion {
  id: string
  name: string
  highlights: Record<string, string[]>
  withDivider?: boolean
}

export interface IActiveSubstance {
  id: string
  type: string
  name: string
  value?: string
  units?: string
}

export interface IMarketSkuSuggestion extends IBaseMarketSuggestion {
  image?: string
  activeSubstance?: IActiveSubstance[]
  category: ICategory
  description?: string
  cultures?: string[]
  producer: string
  type: ESuggestionType.SKU
}

export interface IMarketSupplierSuggestion extends IBaseMarketSuggestion {
  image?: string
  category: ICategory
  type: ESuggestionType.SUPPLIER
}

export interface IMarketProducerSuggestion extends IBaseMarketSuggestion {
  image?: string
  category: ICategory
  type: ESuggestionType.PRODUCER
}

export interface IMarketCategorySuggestion extends IBaseMarketSuggestion {
  type: ESuggestionType.CATEGORY
}

export interface IMarketActiveSubstanceSuggestion extends IBaseMarketSuggestion {
  type: ESuggestionType.ACTIVE_SUBSTANCES
}

export type TMarketSearchSuggestion =
  | IMarketSkuSuggestion
  | IMarketSupplierSuggestion
  | IMarketProducerSuggestion
  | IMarketCategorySuggestion
  | IMarketActiveSubstanceSuggestion

export type TMarketSearchSuggestions = TMarketSearchSuggestion[]

export interface TMarketSearchSuggestionsParameters {
  regionCode?: number
}

export interface IAgrariansFilterSuggestionOrganization {
  id: string
  name: string
  inn: string
  ogrn: string
  kpp: string
  okpo: string
  address: string
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  deliveryBasesData: Array<any>
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  buyerCultures: Array<any>
}

export interface IAgrariansFilterSuggestion {
  id: string
  name: string
}
