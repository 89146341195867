import type { TOnSuggestionsFetchRequested } from '@/components/ui/fields/Autosuggest/interfaces'
import type { TMarketSearchSuggestion, TMarketSearchSuggestions } from '@/services/MarketSearchService/interfaces'
import MarketSearchService from '@/services/MarketSearchService/MarketSearchService'
import { pairwise } from '@/utils/helpers'

const marketSearchServices = new MarketSearchService()

export const convertToSuggestionsWithDividers = (rawSuggestions: TMarketSearchSuggestions): TMarketSearchSuggestions => {
  const convertedSuggestions: TMarketSearchSuggestions = []

  if (rawSuggestions?.length) {
    pairwise(rawSuggestions, (current, next) => {
      if (current.type !== next.type) {
        convertedSuggestions.push({ ...current, withDivider: true })
      } else convertedSuggestions.push(current)
    })

    const suggestion = rawSuggestions.at(-1)
    if (suggestion) convertedSuggestions.push(suggestion)
  }

  return convertedSuggestions
}

export const onSearchAgrariansFetchRequested: TOnSuggestionsFetchRequested<TMarketSearchSuggestion> = async ({ value }) => {
  const suggestions = await marketSearchServices.getAgrariansFromBuyersLk(value)

  return Promise.resolve(convertToSuggestionsWithDividers(suggestions))
}

export const onSearchSellersFetchRequested: TOnSuggestionsFetchRequested<TMarketSearchSuggestion> = async ({ value, statuses }) => {
  const suggestions = await marketSearchServices.getAgrariansOrganizationsFromAccreditation({ statuses, searchField: value })

  return Promise.resolve(convertToSuggestionsWithDividers(suggestions))
}
