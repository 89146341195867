import type { SuggestionSelectedEventData } from 'react-autosuggest'
import compact from 'lodash/compact'
import { history } from '@/browserHistory'
import type { TMarketSearchSuggestion } from '@/services/MarketSearchService/interfaces'
import { ESuggestionType } from '@/services/MarketSearchService/interfaces'
import { EAgriculturalProductsRoute } from '@/types'
import { EAgriculturalProductCategoryId } from '@/types/Catalog'
import type { TFormatMessage } from '@/types/TFormatMessage'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import { SuggestionTypeFilterName } from './constants'

export const getSuggestionLink = (suggestion: TMarketSearchSuggestion): string => {
  const { id, type } = suggestion

  const suggestionFilterName = SuggestionTypeFilterName[type]

  switch (suggestion.type) {
    case ESuggestionType.CATEGORY:
      return createRouterLink(EAgriculturalProductsRoute.category, { categoryId: id })
    case ESuggestionType.SKU:
      return createRouterLink(EAgriculturalProductsRoute.product, { category: suggestion.category.id, productId: id })
    case ESuggestionType.PRODUCER:
    case ESuggestionType.SUPPLIER:
      if (suggestionFilterName) {
        return getUrlWithQuery(
          createRouterLink(EAgriculturalProductsRoute.category, { categoryId: EAgriculturalProductCategoryId.SEARCH }),
          {
            [suggestionFilterName]: id,
          },
        )
      }

      return ''
    case ESuggestionType.ACTIVE_SUBSTANCES:
      if (suggestionFilterName) {
        return getUrlWithQuery(
          createRouterLink(EAgriculturalProductsRoute.category, { categoryId: EAgriculturalProductCategoryId.remedies }),
          { [suggestionFilterName]: id },
        )
      }

      return ''
    default:
      return ''
  }
}

export const makeCategoryParts = (formatMessage: TFormatMessage) => (suggestion: TMarketSearchSuggestion) => {
  const categoryParts: string[] = []

  if (suggestion.type === ESuggestionType.SKU) {
    categoryParts.push(suggestion.category.name)
  } else {
    categoryParts.push(formatMessage({ id: `searchInput.suggestion.category.${suggestion.type}` }))
  }

  return compact(categoryParts)
}

export const onSuggestionSelected = async ({ suggestion, method }: SuggestionSelectedEventData<TMarketSearchSuggestion>) => {
  const suggestionLink = getSuggestionLink(suggestion)
  if (suggestionLink && method === 'enter') history.push(suggestionLink)

  return suggestion
}
