import { ESuggestionType } from '@/services/MarketSearchService/interfaces'
import type { TAgriculturalProductFilterName } from '@/types/Catalog'

export const MIN_LENGTH = 1

export const SuggestionTypeFilterName: Partial<Record<ESuggestionType, TAgriculturalProductFilterName>> = {
  [ESuggestionType.PRODUCER]: 'producerIds',
  [ESuggestionType.SUPPLIER]: 'supplierIds',
  [ESuggestionType.ACTIVE_SUBSTANCES]: 'activeSubstance',
}
