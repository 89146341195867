import type { ElementRef } from 'react'
import React, { memo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import SearchInputWithSuggestions from '@/components/composed/search/SearchInputWithSuggestions'
import { convertToSuggestionsWithDividers } from '@/components/composed/search/SearchInputWithSuggestions/helpers'
import type { TOnSuggestionsFetchRequested } from '@/components/ui/fields/Autosuggest/interfaces'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { MarketSearchService } from '@/services'
import type { TMarketSearchSuggestion } from '@/services/MarketSearchService/interfaces'
import { MIN_LENGTH } from './constants'
import { onSuggestionSelected } from './helpers'
import SuggestionCard from './SuggestionCard'
import type { IAgriculturalProductCatalogSearchInputProps } from './types'

const marketSearchServices = new MarketSearchService()

const getSuggestionValue = (suggestion: TMarketSearchSuggestion) => suggestion.name
const renderSuggestionComponent = (suggestion: TMarketSearchSuggestion) => <SuggestionCard suggestion={suggestion} key={suggestion.id} />

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const AgriculturalProductCatalogSearchInput: React.FC<IAgriculturalProductCatalogSearchInputProps> = props => {
  const { userRegion } = useSelector(mapState)
  const { value, onInput, shouldOpenInModal, className, customInputProps, ...searchInputWithSuggestionsProps } = props

  const inputRef = useRef<ElementRef<typeof SearchInputWithSuggestions>>(null)

  const onSuggestionsFetchRequested: TOnSuggestionsFetchRequested<TMarketSearchSuggestion> = useCallback(
    async ({ value: query }) => {
      const suggestions = await marketSearchServices.getSuggestions(query, { regionCode: userRegion.code })

      return convertToSuggestionsWithDividers(suggestions)
    },
    [userRegion],
  )

  const onInputHandler = useCallback(
    (newValue: string) => {
      const preparedNewValue = newValue?.trim() || null

      if ((preparedNewValue?.length ?? 0) < MIN_LENGTH) return

      inputRef.current?.hideModal()
      onInput(preparedNewValue)
    },
    [onInput],
  )

  return (
    <SearchInputWithSuggestions<TMarketSearchSuggestion>
      ref={inputRef}
      value={value}
      className={className}
      name="searchText"
      customInputProps={{ type: 'text', modifiers: ['white'], ...customInputProps }}
      shouldOpenInModal={!!shouldOpenInModal}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      getSuggestionValue={getSuggestionValue}
      suggestionComponent={renderSuggestionComponent}
      onSuggestionSelected={onSuggestionSelected}
      onInput={onInputHandler}
      {...searchInputWithSuggestionsProps}
    />
  )
}

export default memo(AgriculturalProductCatalogSearchInput)
